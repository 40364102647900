import React from 'react';
import PropTypes from 'prop-types';
import { Link, Tabs, Tab, Toolbar as ToolbarF7 } from 'framework7-react';

import { I18n } from 'Locales';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { Admin, Assignment, Home, Person } from 'Icons';
import { ICON_COLOR, PAGE_NAMES, APP_MODES } from 'Constants';

const DefaultBottomBar = ({
  application,
  offlineResults,
  resetOnboardingProfilePages,
  resetTransient,
  updateApplicationProp,
  user
}) => {
  const bottomBarItems = [
    {
      id: 1,
      label: I18n.t('general:home'),
      icon: 'Home',
      hidden: false,
      pageName: PAGE_NAMES.CAMPAIGNS,
      mode: [APP_MODES.ADMIN, APP_MODES.AGENT]
    },
    {
      id: 2,
      label: I18n.t('general:tools'),
      icon: 'Assigment',
      hidden: false,
      pageName: PAGE_NAMES.TOOLS,
      mode: [APP_MODES.ADMIN, APP_MODES.AGENT],
      iconNotification: offlineResults?.length
    },
    {
      id: 3,
      label: I18n.t('general:admin'),
      icon: 'Admin',
      hidden: !user.mc_pins?.length && user.role_name !== 'Admin',
      pageName: PAGE_NAMES.ADMIN,
      mode: [APP_MODES.ADMIN]
    },
    {
      id: 4,
      label: I18n.t('general:profile'),
      icon: 'Person',
      hidden: false,
      pageName: PAGE_NAMES.PROFILE,
      mode: [APP_MODES.ADMIN, APP_MODES.AGENT],
      iconNotification:
        (user?.blank_fields?.length || 0) +
        (user?.needs_attention_documents?.length || 0) +
        (user?.rejected_fields?.length || 0)
    }
  ];

  const handleNavigate = ({
    application,
    item,
    resetOnboardingProfilePages,
    resetTransient,
    updateApplicationProp
  }) => {
    const currentPageName = application.selectedAppBottomBarSection.pageName;
    NavigationService.navigate({
      name: item.pageName
    });

    if (item.pageName === PAGE_NAMES.PROFILE && currentPageName !== item.pageName) {
      resetTransient();
    }

    if (item.pageName === PAGE_NAMES.ADMIN) {
      resetOnboardingProfilePages();
    }

    updateApplicationProp('selectedAppBottomBarSection', item);
  };

  const componentMap = {
    Home: Home,
    Assigment: Assignment,
    Admin: Admin,
    Person: Person
  };

  const renderBottomBarItem = (item, index) => {
    const IconComponent = componentMap[item.icon];

    const itemSelected = item.label === application?.selectedAppBottomBarSection?.label;

    return (
      <RowView height={74} key={index} paddingVerticaly={12}>
        <Link
          tabLink={item.label}
          tabLinkActive={itemSelected}
          onClick={handleNavigate.bind(null, {
            application,
            item,
            resetOnboardingProfilePages,
            resetTransient,
            updateApplicationProp
          })}>
          <div className="position-relative">
            <IconComponent fillColor={itemSelected ? ICON_COLOR.BLUE : ICON_COLOR.GRAY} />
            {!!item.iconNotification && <span className="dot-notification">{item.iconNotification}</span>}
          </div>
          <div className={itemSelected ? 'text-blue' : ''}>{item.label}</div>
        </Link>
      </RowView>
    );
  };

  const renderTabItem = (item, index) => (
    <ColumnView key={index}>
      <Tab id={item.label} tabActive={item.label === application?.selectedAppBottomBarSection?.label} />
    </ColumnView>
  );

  const filteredBottomBarItems = bottomBarItems.filter(item => item.mode.includes(application?.mode) && !item.hidden);

  return (
    <>
      <ToolbarF7 position={'bottom'} tabbar noShadow noHairLine scrollable id={'bottom-bar'}>
        {filteredBottomBarItems.map(renderBottomBarItem)}
      </ToolbarF7>
      <Tabs>{filteredBottomBarItems.map(renderTabItem)}</Tabs>
    </>
  );
};

DefaultBottomBar.propTypes = {
  application: PropTypes.object.isRequired,
  offlineResults: PropTypes.array,
  resetOnboardingProfilePages: PropTypes.func,
  resetTransient: PropTypes.func,
  updateApplicationProp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default DefaultBottomBar;
